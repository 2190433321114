import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { Children } from '../../../../components/miscellianous/children'
import { BSInputContext } from '../../../../core/context/beem-shot/BSInputContext/BSInputContext'
import { BSInput } from '../../../../core/dto/beem-shot/BSInput/BSInput'
import { BSInputUpdateDto } from '../../../../core/dto/beem-shot/BSInput/BSInputUpdateDto'
import { BuildingFacadeEnum } from '../../../../core/enum/beem-shot/BuildingFacadeEnum'
import { BuildingFrameEnum } from '../../../../core/enum/beem-shot/BuildingFrameEnum'
import { CalculTypeEnum } from '../../../../core/enum/beem-shot/CalculTypeEnum'
import { ElevatorEnum } from '../../../../core/enum/beem-shot/ElevatorEnum'
import { FacadeCoverEnum } from '../../../../core/enum/beem-shot/FacadeCoverEnum'
import { FlatTypeEnum } from '../../../../core/enum/beem-shot/FlatTypeEnum'
import { FloorCoverEnum } from '../../../../core/enum/beem-shot/FloorCoverEnum'
import { FloorTypeEnum } from '../../../../core/enum/beem-shot/FloorTypeEnum'
import { FoundationTypeEnum } from '../../../../core/enum/beem-shot/FoundationTypeEnum'
import { HeatingTypeEnum } from '../../../../core/enum/beem-shot/HeatingTypeEnum'
import { OfficeTypeEnum } from '../../../../core/enum/beem-shot/OfficeTypeEnum'
import { ParkingEnum } from '../../../../core/enum/beem-shot/ParkingEnum'
import { PhotovoltaicEnum } from '../../../../core/enum/beem-shot/PhotovoltaicEnum'
import { RoofEnum } from '../../../../core/enum/beem-shot/RoofEnum'
import { RoofFlatCoverEnum } from '../../../../core/enum/beem-shot/RoofFlatCoverEnum'
import { RoofSlopedCoverEnum } from '../../../../core/enum/beem-shot/RoofSlopedCoverEnum'
import { SchoolTypeEnum } from '../../../../core/enum/beem-shot/SchoolTypeEnum'
import { ClimaticZoneEnum } from '../../../../core/enum/climaticZoneEnum'
import { UsageEnum } from '../../../../core/enum/usageEnum'
import { useForm } from '../../../../core/hooks/form/use-form'
import { nonZero, required } from '../../../../core/hooks/form/validation'
import { identity } from '../../../../core/services/helper-service'

export const SimplifiedQuestionnaireContext = React.createContext<SimplifiedQuestionnaireStore>(
  {} as SimplifiedQuestionnaireStore
)

function formToDto(id: string, form: IForm): BSInputUpdateDto {
  return {
    id,
    surfacePlancher: form.surfacePlancher,
    surfaceHabitable: form.surfaceHabitable,
    surfaceComble: form.surfaceComble,
    usage: form.usage,
    typeBureau: form.typeBureau,
    nombreLogement: form.nombreLogement,
    numberT1: form.numberT1,
    numberT2: form.numberT2,
    numberT3: form.numberT3,
    numberT4: form.numberT4,
    numberT5: form.numberT5,
    typeLogement: form.typeLogement,
    typeEnseignement: form.typeEnseignement,
    typeChauffage: form.typeChauffage,
    empriseAuSol: form.empriseAuSol,
    nombreEtage: form.nombreEtage,
    hauteurMoyenneEtage: form.hauteurMoyenneEtage,
    typeToiture: form.typeToiture,
    couvertureToiturePlate: form.couvertureToiturePlate,
    couvertureToiturePente: form.couvertureToiturePente,
    largeurMoyenneBatiment: form.largeurMoyenneBatiment,
    longueurMoyenneBatiment: form.longueurMoyenneBatiment,
    nombreEmployes: form.nombreEmployes,
    nombreAscenseur: form.nombreAscenseur,
    typeAscenseur: form.typeAscenseur,
    photovoltaique: form.photovoltaique,
    surfacePanneauPhotovoltaique: form.surfacePanneauPhotovoltaique,
    typePhotovoltaique: form.typePhotovoltaique,
    nombreSalleDeClasse: form.nombreSalleDeClasse,
    typeParking: form.typeParking,
    nombrePlaceParking: form.nombrePlaceParking,
    nombreEtageParking: form.nombreEtageParking,
    typeCalcul: form.typeCalcul,
    climaticZone: form.climaticZone,
    surfaceParcelle: form.surfaceParcelle,
    altitude: form.altitude,
    typeFondation: form.typeFondation,
    recuperationEauPluie: form.recuperationEauPluie,
    typeSol: form.typeSol,
    typeOssature: form.typeOssature,
    typeFacade: form.typeFacade,
    couvertureFacade: form.couvertureFacade,
    couvertureSol: form.couvertureSol,
    reseauChaleur: form.reseauChaleur,
  }
}

function dtoToForm(dto: BSInput | undefined): IForm {
  return dto ? { ...dto } : ({} as IForm) // when bs input is undefined, the form will not be visible, so no need for a default object with values
}

export function SimplifiedQuestionnaireProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsInput, updateBSInput, refreshBSInput } = useContext(BSInputContext)
  const initialDto = useMemo(() => bsInput, [bsInput])
  const isFormModified = useRef<boolean>(false)

  const submit = useCallback(
    (someForm: IForm) => {
      if (bsInput?.id) {
        // optimistic update
        return updateBSInput(formToDto(bsInput.id, someForm), true)
      } else {
        return Promise.resolve()
      }
    },
    [bsInput, updateBSInput]
  )

  const rules = useMemo(
    () => [
      required('typeCalcul'),
      required('usage'),
      required('typeLogement'),
      required('numberT1'),
      required('numberT2'),
      required('numberT3'),
      required('numberT4'),
      required('numberT5'),
      required('typeBureau'),
      required('typeEnseignement'),
      required('climaticZone'),
      required('altitude'),
      required('typeParking'),
      required('nombreAscenseur'),
      required('typeFondation'),
      required('typeSol'),
      required('typeOssature'),
      required('typeFacade'),
      required('typeToiture'),
      required('couvertureSol'),
      required('couvertureFacade'),
      required('couvertureToiturePlate'),
      required('couvertureToiturePente'),
      required('reseauChaleur'),
      required('typeChauffage'),
      required('recuperationEauPluie'),
      required('photovoltaique'),
      nonZero('nombreEtage'),
      nonZero('nombrePlaceParking'),
      nonZero('surfacePlancher'),
      nonZero('surfaceHabitable'),
      nonZero('surfaceParcelle'),
      nonZero('empriseAuSol'),
      nonZero('longueurMoyenneBatiment'),
      nonZero('hauteurMoyenneEtage'),
      nonZero('nombreEtage'),
    ],
    []
  )

  const { form, errors, handleChange, handleSubmit, validate, setRules } = useForm(initialDto, dtoToForm, rules, submit)

  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const optimisticSaveForm = useCallback((): Promise<void> => {
    const e = { preventDefault: identity } as FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>
    return handleSubmit(e)
  }, [handleSubmit])

  const formChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      isFormModified.current = true
      handleChange(e)
    },
    [handleChange]
  )

  const cancelTimer = useCallback((): void => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
  }, [])

  const debounceSubmit = useCallback((): void => {
    if (timerRef.current) {
      cancelTimer()
    }
    timerRef.current = setTimeout(() => {
      optimisticSaveForm()
    }, 1500)
  }, [cancelTimer, optimisticSaveForm])

  const clearOnDestroy: () => Promise<void> = useCallback(() => {
    if (timerRef.current) {
      cancelTimer()
    }
    return optimisticSaveForm()
  }, [cancelTimer, optimisticSaveForm])

  useEffect(() => {
    if (isFormModified.current) {
      debounceSubmit()
    }
  }, [form, debounceSubmit])

  useEffect(
    // And this useEffect calls refresh the bs input, up to date, only when the component is destroyed. Not when a dependency of the useCallback changes
    () => () => {
      refreshBSInput()
    },
    [refreshBSInput]
  )

  useEffect(() => {
    form.photovoltaique
      ? setRules([...rules, nonZero('surfacePanneauPhotovoltaique')])
      : setRules([...rules, required('surfacePanneauPhotovoltaique')])
  }, [form.photovoltaique, rules, setRules])

  useEffect(() => {
    setRules([
      ...rules,
      {
        fieldName: 'surfacePlancher',
        rule: () => form.surfacePlancher > form.surfaceHabitable,
        errorMessage: 'La surface plancher doit être plus grande que la surface SHAB.',
      },
      {
        fieldName: 'surfacePlancher',
        rule: () => form.surfacePlancher >= form.empriseAuSol,
        errorMessage: "La surface plancher doit être plus grande ou égale à l'emprise au sol.",
      },
      {
        fieldName: 'empriseAuSol',
        rule: () => form.empriseAuSol <= form.surfaceParcelle,
        errorMessage: "L'emprise au sol doit être plus petite ou égale à la surface parcelle.",
      },
    ])
  }, [form.empriseAuSol, form.surfaceHabitable, form.surfaceParcelle, form.surfacePlancher, rules, setRules])

  const simplifiedQuestionnaireStore = useMemo(
    () => ({
      form,
      errors,
      handleChange: formChange,
      validate,
      clearOnDestroy,
    }),
    [form, errors, formChange, validate, clearOnDestroy]
  )

  return (
    <SimplifiedQuestionnaireContext.Provider value={simplifiedQuestionnaireStore}>
      {children}
    </SimplifiedQuestionnaireContext.Provider>
  )
}

type SimplifiedQuestionnaireStore = {
  form: IForm
  errors: Record<string, string | undefined>
  handleChange(event: ChangeEvent<HTMLInputElement>): void
  validate(): boolean
  clearOnDestroy(): Promise<void>
}

interface IForm {
  typeCalcul: CalculTypeEnum
  usage: UsageEnum
  typeBureau: OfficeTypeEnum
  typeLogement: FlatTypeEnum
  typeEnseignement: SchoolTypeEnum
  surfacePlancher: number
  surfaceHabitable: number
  surfaceComble: number | undefined
  nombreLogement: boolean
  numberT1: number
  numberT2: number
  numberT3: number
  numberT4: number
  numberT5: number
  typeChauffage: HeatingTypeEnum
  empriseAuSol: number // emprise au sol
  nombreEtage: number // nombre d'étages
  hauteurMoyenneEtage: number // hauteur moyenne d'un étage
  couvertureToiturePlate: RoofFlatCoverEnum // type de revêtement de la toiture
  couvertureToiturePente: RoofSlopedCoverEnum // type de revêtement de la toiture pente
  largeurMoyenneBatiment: number // largeur du bâtiment
  longueurMoyenneBatiment: number // longueur moyenne du bâtiment
  nombreEmployes: number
  nombreAscenseur: number
  typeAscenseur: ElevatorEnum
  photovoltaique: boolean
  surfacePanneauPhotovoltaique: number
  typePhotovoltaique: PhotovoltaicEnum
  nombreSalleDeClasse: number
  typeParking: ParkingEnum
  nombrePlaceParking: number
  nombreEtageParking: number
  climaticZone: ClimaticZoneEnum
  surfaceParcelle: number
  altitude: number
  typeFondation: FoundationTypeEnum
  recuperationEauPluie: boolean
  typeToiture: RoofEnum
  typeSol: FloorTypeEnum
  typeOssature: BuildingFrameEnum
  typeFacade: BuildingFacadeEnum
  couvertureFacade: FacadeCoverEnum
  couvertureSol: FloorCoverEnum
  reseauChaleur: boolean
}
