import { Box, Tab, Tabs, Tooltip } from '@mui/material'
import React from 'react'
import { DefaultTypography } from '../../../components/typography/default-typography'

export interface TabPanelProps {
  id: string
  Icon: React.ElementType
  label: string
  chips: string | undefined
  content: JSX.Element
  disabled?: boolean
  disabledText?: string
}

interface ChildComponentProps {
  panels: TabPanelProps[]
  handleChange(event: React.SyntheticEvent, newValue: string): void
  tabs: string
}

function a11yProps(index: any): { id: string; 'aria-controls': string } {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export function CustomTabPanel({ panels, tabs, handleChange }: Readonly<ChildComponentProps>): React.JSX.Element {
  const updatedPanels = panels.map((panel) => ({
    ...panel,
    disabled: panel.disabled !== undefined ? panel.disabled : false,
    disabledText: panel.disabledText !== undefined && panel.disabled ? panel.disabledText : '',
  }))

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabs}
          onChange={(event, newValue) => {
            const selectedPanel = updatedPanels.find((panel) => panel.id === newValue)
            if (selectedPanel && selectedPanel.disabled) {
              event.preventDefault()
            } else {
              handleChange(event, newValue)
            }
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#40548D',
            },
          }}>
          {updatedPanels.map((panel, index) => (
            <Tooltip title={panel.disabledText} key={panel.id}>
              <Tab
                value={panel.id}
                onClick={(e) => panel.disabled && e.preventDefault()}
                label={
                  <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                    {panel.Icon && <panel.Icon sx={{ color: panel.id === tabs ? '#40548D' : '#8398A6' }} />}
                    <DefaultTypography
                      label={panel.label}
                      color={panel.id === tabs ? '#40548D' : '#8398A6'}
                      fontSize='16px'
                      fontWeight={500}
                    />

                    {panel.chips && (
                      <Box sx={{ background: '#CEDEEF', px: 1, borderRadius: 4 }}>
                        <DefaultTypography label={panel.chips} color='#40548D' />
                      </Box>
                    )}
                  </Box>
                }
                {...a11yProps(index)}
                sx={{ cursor: panel.disabled ? 'not-allowed' : 'pointer' }}
              />
            </Tooltip>
          ))}
        </Tabs>
      </Box>
      {panels.map((x) => x.id === tabs && <Box key={x.id}> {x.content}</Box>)}
    </>
  )
}
