import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { IconBuildingCommunity } from '../../components/custom-icons/icon-building-community'
import { IconBuildingCommunityCustom } from '../../components/custom-icons/icon-building-community-custom'
import { IconUserCheck } from '../../components/custom-icons/icon-user-check'
import { IconUsers } from '../../components/custom-icons/icon-users'
import { ICADE_SIRET, pagesUrl } from '../../core/appConstants'
import { AppNavBarContext } from '../../core/context/nav-bar/AppNavBarContext'
import { OrganizationContext } from '../../core/context/organization/organization-context'
import { AppSideBarContext } from '../../core/context/side-bar/side-bar-context'
import { UserContext } from '../../core/context/user/user-context'
import { Collaborator } from '../../core/dto/user/collaborator'
import { OrganizationRoleEnum } from '../../core/enum/user/organization-role-enum'
import { CustomTabPanel, TabPanelProps } from '../beem-shot/components/CustomTabPanel'
import { KairnialProjectsComponent } from './components/KairnialProjectsComponent'
import OrganizationCollaborators from './organization-collaborators'
import OrganizationContact from './organization-contact'
import OrganizationDetail from './organization-detail'

export function MyOrganization(): React.JSX.Element {
  const [collabList, setCollabList] = useState<Collaborator[]>([])
  const { user } = useContext(UserContext)
  const { refreshOrganization, organization } = useContext(OrganizationContext)

  const location = useLocation()
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTitle, setPreviousUrl, setPagination, setIcon } = useContext(AppNavBarContext)

  useEffect(() => {
    setShowAppSide(true)
    setTitle('Mon organisation')
    setPagination(['Mon compte'])
    setIcon(<IconBuildingCommunityCustom />)
    setPreviousUrl(pagesUrl.BEEM_SHOT_HOME)
  }, [setShowAppSide, location, setTitle, setPreviousUrl, setPagination, setIcon])

  useEffect(() => {
    refreshOrganization()
  }, [refreshOrganization])

  const isWriting: boolean = user?.role === OrganizationRoleEnum.MEGA_USER

  const [tabs, setTabs] = React.useState('ORGANIZATION_INFO')

  function handleChange(event: React.SyntheticEvent, newValue: string): void {
    setTabs(newValue)
  }

  const panels: TabPanelProps[] = [
    {
      id: 'ORGANIZATION_INFO',
      Icon: IconBuildingCommunity,
      label: 'Informations',
      chips: undefined,
      content: <OrganizationDetail isWriting={isWriting} />,
    },
    {
      id: 'ORGANIZATION_COLLAB',
      Icon: IconUserCheck,
      label: 'Collaborateurs',
      chips: undefined,
      content: <OrganizationCollaborators dataCollab={collabList} setCollabList={setCollabList} />,
    },
    {
      id: 'ORGANIZATION_CONTACT',
      Icon: IconUsers,
      label: 'Contact',
      chips: undefined,
      content: <OrganizationContact />,
    },
    ...(organization?.siret === ICADE_SIRET
      ? [
          {
            id: 'ORGANIZATION_KARNIAL_PROJECTS',
            Icon: FormatListBulletedIcon,
            label: 'Projets Karnial',
            chips: undefined,
            content: <KairnialProjectsComponent />,
          },
        ]
      : []),
  ]

  return <CustomTabPanel panels={panels} tabs={tabs} handleChange={handleChange} />
}
