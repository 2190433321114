import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Children } from '../../../../components/miscellianous/children'
import { currentCodeVersion } from '../../../appConstants'
import { Address } from '../../../dto/address'
import { BSProject } from '../../../dto/beem-shot/BSProject/BSProject'
import { BSProjectCreationDto } from '../../../dto/beem-shot/BSProject/BSProjectCreationDto'
import { BSProjectUpdateDto } from '../../../dto/beem-shot/BSProject/BSProjectUpdateDto'
import { CodesVersion } from '../../../enum/codesVersionEnum'
import { ProjectStatusEnum } from '../../../enum/projectStatusEnum'
import { useBSProject } from '../../../hooks/beem-shot/useBSProject'
import { CodeReferenceContextProvider } from '../../code-acv/CodeReferenceContext'

export interface IForm {
  id: string | undefined
  name: string
  businessCode: string
  licenceDate?: Date
  street: string
  city: string
  zipCode: string
  additional: string
}

export function formToDto(form: IForm): BSProjectCreationDto {
  return {
    name: form.name,
    businessCode: form.businessCode,
    licenceDate: form.licenceDate,
    address: {
      street: form.street?.trim(),
      city: form.city?.trim(),
      zipCode: form.zipCode?.trim(),
      additional: form.additional?.trim(),
    },
  }
}

export function updateFormToDto(bsProject: BSProject, form?: IForm): BSProjectUpdateDto {
  return form
    ? {
        id: bsProject.id ?? '',
        organizationId: bsProject.organizationId,
        name: form.name,
        businessCode: form.businessCode,
        licenceDate: form.licenceDate,
        address: {
          street: form.street,
          city: form.city,
          zipCode: form.zipCode,
          additional: form.additional,
        },
        codesVersion: bsProject.codesVersion,
        status: bsProject.status,
        coverImgFileName: bsProject.coverImgFileName,
        coverImgFileType: bsProject.coverImgFileType,
        coverImgAwsFileKey: bsProject.coverImgFileName,
      }
    : {
        id: bsProject.id ?? '',
        organizationId: bsProject.organizationId,
        name: bsProject.name,
        businessCode: bsProject.businessCode,
        licenceDate: bsProject.licenceDate,
        address: {
          street: bsProject.address.street,
          city: bsProject.address.city,
          zipCode: bsProject.address.zipCode,
          additional: bsProject.address.additional,
        },
        codesVersion: bsProject.codesVersion,
        status: bsProject.status,
        coverImgFileName: bsProject.coverImgFileName,
        coverImgFileType: bsProject.coverImgFileType,
        coverImgAwsFileKey: bsProject.coverImgFileName,
      }
}

export function dtoToForm(bsProject: BSProject | undefined): IForm {
  return bsProject
    ? {
        id: bsProject.id,
        name: bsProject.name,
        businessCode: bsProject.businessCode,
        licenceDate: bsProject.licenceDate,
        street: bsProject.address.street,
        city: bsProject.address.city,
        zipCode: bsProject.address.zipCode,
        additional: bsProject.address.additional,
      }
    : {
        id: '',
        name: '',
        businessCode: '',
        licenceDate: new Date(),
        street: '',
        city: '',
        zipCode: '',
        additional: '',
      }
}

export function dtoToBsProject(dto: BSProject): BSProject {
  return {
    id: dto.id,
    organizationId: dto.organizationId,
    name: dto.name || '',
    businessCode: dto.businessCode || '',
    licenceDate: dto.licenceDate,
    address: dto.address || new Address(),
    codesVersion: dto.codesVersion || currentCodeVersion,
    status: dto.status || ProjectStatusEnum.IN_PROGRESS,
    coverImgAwsFileKey: dto.coverImgAwsFileKey || '',
    coverImgFileName: dto.coverImgFileName || '',
    coverImgFileType: dto.coverImgFileType || '',
    createdByUser: dto.createdByUser || '',
    lastModifiedDate: dto.lastModifiedDate ? new Date(dto.lastModifiedDate) : undefined,
  }
}

export const BSProjectContext = React.createContext<BeemShotProjectStore>({} as BeemShotProjectStore)

export function BSProjectContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsProjectId } = useParams()
  const { bsVariantId } = useParams()

  const { getBSProject, createBSProject, updateBSProject, getStartEstimating } = useBSProject()

  const [bsProject, setBsProject] = useState<BSProject | undefined>()

  const createProject = useCallback(
    (bsProjectCreationDto: BSProjectCreationDto) =>
      createBSProject(bsProjectCreationDto).then((newBsProject) => {
        setBsProject(newBsProject)
        return newBsProject
      }),
    [createBSProject]
  )

  const updateProject = useCallback(
    (bsProjectUpdateDto: BSProjectUpdateDto) => {
      if (bsProjectId && bsProjectId !== 'new') {
        updateBSProject(bsProjectUpdateDto).then((updatedBsProject) => {
          setBsProject(updatedBsProject)
        })
      }
      return Promise.resolve()
    },
    [bsProjectId, updateBSProject]
  )

  const fetchBsProject = useCallback(
    (id: string): Promise<void> =>
      getBSProject(id).then((newBSProject) => {
        if (newBSProject) {
          setBsProject(dtoToBsProject(newBSProject))
        }
      }),
    [getBSProject]
  )

  const refreshProject = useCallback((): void => {
    if (bsProjectId) {
      getBSProject(bsProjectId).then((newBSProject) => {
        if (newBSProject) {
          setBsProject(dtoToBsProject(newBSProject))
        }
      })
    } else {
      setBsProject(undefined)
    }
  }, [bsProjectId, getBSProject])

  const startEstimating = useCallback(() => {
    if (bsVariantId) {
      return getStartEstimating(bsVariantId).then(() => undefined)
    } else {
      return Promise.resolve(undefined)
    }
  }, [getStartEstimating, bsVariantId])

  useEffect(() => {
    refreshProject()
  }, [refreshProject])

  const bsProjectStore = useMemo(
    () => ({
      bsProject,
      setBsProject,
      createProject,
      updateProject,

      startEstimating,
      refreshProject,
      fetchBsProject,
    }),
    [bsProject, createProject, updateProject, startEstimating, refreshProject, fetchBsProject]
  )

  return (
    <BSProjectContext.Provider value={bsProjectStore}>
      <CodeReferenceContextProvider projectCodeVersion={bsProject?.codesVersion || CodesVersion.VERSION_1_6}>
        {children}
      </CodeReferenceContextProvider>
    </BSProjectContext.Provider>
  )
}

export type BeemShotProjectStore = {
  bsProject: BSProject | undefined
  setBsProject: Dispatch<SetStateAction<BSProject | undefined>>
  createProject(beemShotProjectCreationDto: BSProjectCreationDto): Promise<BSProject | void>
  updateProject(bsProjectUpdateDto: BSProjectUpdateDto): Promise<BSProject | void>
  startEstimating(): Promise<undefined>
  refreshProject(): void
  fetchBsProject(bsProjectId: string): Promise<void>
}
